.Title {
  font-size: 1.3vw;
  font-weight: bold;
  margin: 0;
  color: #00ff00;
  overflow: hidden;
  user-select: none;
  text-shadow: 0px 0px 30px #00ff00;
}

@media (min-width: 900px) { 
  .Title {
    font-size: 1vw;
  }
 }

 @media (min-width: 1900px) { 
  .Title {
  font-size: 0.8vw;
  }
 }

 @media (min-width: 2560px) { 
  .Title {
  font-size: 0.7vw;
  }
 }